:root{
    --background1:#FFD80A;
    --background2:#FFDC00;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
    color: var(--background2) !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
    color: var(--background1) !important;
}
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    font-size: 15px !important;
}
.swal2-title {
    font-size: 17px !important;
    font-weight: bold;
}
#swal2-title{
    font-weight: bold;
    color:#343841;
}
.swal2-container.swal2-center>.swal2-popup{
    padding: 2rem 0;
    border-radius: 16px;
}
button.swal2-cancel.swal2-styled{
    border: 1px solid #E7E7E7;
    background-color: #fff;
    color: #343841;
    min-width: 130px;
    height: 48px;
    border-radius: 10px;
    font-size: 14px;
}
button.swal2-confirm.swal2-styled {
    color: #343841;
    min-width: 130px;
    height: 48px;
    border-radius: 10px;
    font-size: 14px;
}
.swal2-styled.swal2-confirm{
    background: var(--background1) !important;
}
.swal2-actions{
    display: flex;
    flex-direction: row-reverse;
}

.swal2-styled.swal2-cancel{
    outline: none;
}
.swal2-popup .swal2-styled{
    cursor: pointer;
}
.swal2-popup .swal2-styled:focus {
    box-shadow: none !important;
}
.swal-input{
    height: auto !important;
    padding: 6px !important;
    margin: 0 auto !important;
    font-size: 17px;
}
.swal-input::placeholder{
    font-size: 17px;
}
.swal-input:focus{
    border: 2px solid var(--background1) !important;
}
body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

body::-webkit-scrollbar {
    /* Chrome, Safari, Opera*/
    display: none;
}

body::-webkit-scrollbar-thumb {
    /* Chrome, Safari, Opera*/
    background: #3250D255;
}

.none-scroll {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
}
.none-scroll::-webkit-scrollbar {
    display: none !important; /* Chrome, Safari, Opera*/
}
@font-face {

}
.sidebar-menu-list{
}
.ql-editor{
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    max-height: auto !important;
}
.ql-editor::-webkit-scrollbar{
    display: none;
}
.ql-editor{
    padding: 16px 0 !important;
}
.ql-editor > p > img{
    width: 100% !important;
}
.show-scrollbar ::-webkit-scrollbar {
    display: block !important;
}

.board-container {
    width: 100%;
}

.emoji {
    background: transparent;
}
.slick-dots {
    bottom: -28px !important;
}

.slick-dots li.slick-active button:before {
    color: #FECC2F !important;
}

#editor {
    position: relative !important;
}

.emoji-picker-react {
    position: absolute !important;
    z-index: 100;
    top: 56px;
    right: 36px;
    display: none !important;
}
.scroll-table::-webkit-scrollbar{
    display: none;
}
.scroll-table::-webkit-scrollbar-thumb {
    /* Chrome, Safari, Opera*/
    background: #3250D255;
}
.scroll-table-green::-webkit-scrollbar{
    display: none;
    
}
.scroll-table-green::-webkit-scrollbar-thumb {
    /* Chrome, Safari, Opera*/
    background: #3250D255;
    position: fixed;
    bottom: 0;
}
.scroll-css {}

.scroll-css::-webkit-scrollbar {
    width: 10px;
}

.scroll-css::-webkit-scrollbar-thumb {
    background-color: #3250D255;
}

.scroll-css::-webkit-scrollbar-track {
    background-color: #ffffff;
}
#ALLAT_MOBILE_PAY{
    height: 100vh !important;
}
.banner-img{
    width: 100vw;
    height: 30vw;
}
.enrolment-banner-img{
    width: 1000px;
    height: 250px;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {}

.menu-icon {
    width: 1.5rem;
    height: 1.3rem;
    background: 50% no-repeat;
    background-size: contain;
    margin: 0 auto;
    position: relative;
    padding: 0.6rem;
}

#editor {
    margin: 24px;
}

#board-content {
    width: 2.2rem;
    font-size: 2rem;
    margin: 1.5rem 0 0.5rem 0;
}

#board-text {
    font-size: 12px;
}

.subtype-container::-webkit-scrollbar {
    display: none;
}
.viewer > div > div > div.ql-editor > p > a > img{
    max-width: 1000px !important;
}
.viewer > div > div > div.ql-editor > p > img{
    max-width: 1000px !important;
}
.comment-img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 16px;
}

.note {
    width: 100%;
    margin: 16px 0;
}

div.note>img {
    width: 100%;
}

div.note>p>img {
    width: 100% !important;
}

.pointer {
    cursor: pointer;
}

.bar {
    flex: auto;
    border: 1px solid #4CDAD8;
    height: 2px;
    background: #4CDAD8;
}

.smaller-margin-right-content {
    margin: 0 1rem 0 auto;
}
.width-100{
    width: 100% !important;
}
.card-img {
    width: 100% !important;
    height: 320px;
    background: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-blend-mode: multiply;
}
.theme-img{
    width: 150px;
    background:#fff;
}
.video-img{
    width: 90%;
height:320px;
background:#fff;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;
background-blend-mode: multiply;
margin: 18px auto;

}
#ALLAT_MOBILE_PAY{
    position: fixed !important;
}
.slick-next{
    display: none !important;
}
.slick-prev{
    display: none !important;
}

.master-dropdown-content{
    position: fixed;
    display: none;
    top:10rem;
    left:0;
    width:100vw;
    background: #fff;
    box-shadow: 0px 12px 12px #00000029;
    border-top:1px solid #ccc;
    padding:1rem 0;
    animation: fadein 0.5s;
}
.service-dropdown-content{
    position: absolute;
    display: none;
    top:6.12rem;
    right:-75px;
    background: #fff;
    color: #707070 !important;
    font-weight: normal;
    font-size: 15px;
    box-shadow: 0px 12px 12px #00000029;
    border-top:1px solid #ccc;
    animation: fadein 0.5s;
    width: 150px;
}
.service-dropdown-content  div{
    padding: 8px !important;
}

@media screen and (max-width:1200px) {
    .video-img{
        height: 28.266666666vw;
    }
    .viewer > div > div > div.ql-editor > p > a > img{
        width: 100% !important;
    }
    .viewer > div > div > div.ql-editor > p > img{
        width: 100% !important;
    }
}

@media screen and (max-width:1100px) {
    .card-img {
        height: 28.8vw;
    }
}
@media screen and (max-width:1000px) {
    .theme-img{
        width:15vw;

    }
    .enrolment-banner-img{
        width: 100vw;
        height: 25vw;
    }
}
@media screen and (max-width:1150px) {}

@media screen and (max-width:1000px) {
    #editor {
        margin: 16px auto;
        width: 90%;
        padding: 8px;
    }
}
@media screen and (max-width:700px) {
    .theme-img {
        width: 30vw;
    }
   
}

@media screen and (max-width:600px) {
    .video-img{
        height: 50vw;
        margin: 5vw auto;
    }
    .card-img {
        height: 52.2222222222vw;
    }

    .prevArrow {
        position: absolute;
        top: 30vw;
        left: 6px;
        z-index: 2;
        cursor: pointer;
        font-size: 28px;
        padding: 4px 6px 0px 3px;
        border-radius: 50%;
        background: #aaaaaa55;
        color: #fff !important;

    }

    .nextArrow {
        position: absolute;
        top: 30vw;
        right: 6px;
        z-index: 2;
        cursor: pointer;
        font-size: 28px;
        padding: 4px 4px 0px 5px;
        border-radius: 50%;
        background: #aaaaaa55;
        color: #fff !important;

    }

    .progress-bar {
        appearance: none;
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;

    }

    .progress-bar::-webkit-progress-bar {
        background: #f0f0f0;
        border-radius: 0;
    }

    .progress-bar::-webkit-progress-value {
        border-radius: 0;
        background: #4CDAD8;
    }
}

@media screen and (max-width:600px) {
    .smaller-margin-right-content {
        margin: 0 0.5rem 0 auto;
    }
}

.dark-mode {
    background: #222 !important;
    color: #fff !important;
}